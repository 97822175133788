import React from "react"
import { Link } from "gatsby"

import * as styles from './ProjectSlider.module.scss'
import placeholder from '../../images/placeholder.jpg';

class ProjectSlider extends React.Component {
  render() {
    return (
      <div className={styles.projectSlider}>
        {
          this.props.projects.map(function (project, i) {
            const featuredImage = project.featuredImage
              ? (
                <img src={project.featuredImage.node.mediaDetails.sizes.filter(size => size.name === 'thumbnail')[0].sourceUrl} alt={project.featuredImage.altText} />
              )
              : <img src={placeholder} alt="" />

            return (
              <Link key={i} to={project.uri} className={styles.projectItem}>
                {featuredImage}
              </Link>
            )
          })
        }
      </div>
    )
  }
}

export default ProjectSlider
