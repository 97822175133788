import React, { useState } from 'react';
import { graphql, useStaticQuery } from "gatsby"

import Cartouche from "../components/cartouche"
import CollapsingCard from "../components/CollapsingCard"
import Layout from "../components/layout"
import ProjectSlider from "../components/ProjectSlider"
import Seo from "../components/seo"

const IndexPage = () => {
  const [state] = useState({
    filterByCategory: undefined
  });

  const data = useStaticQuery(graphql`
    query getPageIndex {
      wpPage(slug: {eq: "accueil"}) {
        id
        title
        content
        slug
        featuredImage {
        node {
            imageCustomFields {
              position
            }
            localFile {
              publicURL
            }
          }
        }
      }
      allWpPost(sort: {fields: date, order: DESC}, limit: 2) {
        posts: nodes {
          id
          title
          content
          slug
          uri
          date(formatString: "Do MMMM YYYY", locale: "fr")
          featuredImage {
            node {
              imageCustomFields {
                position
              }
              altText
              mediaDetails {
                sizes {
                  name
                  sourceUrl
                }
              }
            }
          }
          categories {
            nodes {
              name
              slug
            }
          }
        }
      }

      allWpProject(sort: {fields: date, order: DESC}, limit: 4) {
        projects: nodes {
          id
          title
          slug
          uri
          projectDetails {
            year
          }
          projectCategories {
            nodes {
              slug
              name
            }
          }
          featuredImage {
            node {
              imageCustomFields {
                position
              }
              altText
              mediaDetails {
                sizes {
                  name
                  sourceUrl
                }
              }
            }
          }
        }
      }
    }
  `)

  if (!data) return null;


  const {
    title,
    content,
    featuredImage
  } = data.wpPage

  const {
    projects,
  } = data.allWpProject

  const {
    posts,
  } = data.allWpPost

  return (
    <Layout featuredImage={featuredImage}>
      <Seo title={title} />
      <div className="container">
        <div className="row">
          <div className="column-left">
          </div>
          <div className="column-right mt-negative">
            <Cartouche>
              <h1>atelier d'architecture</h1>
              <div dangerouslySetInnerHTML={{ __html: content }}></div><br />
            </Cartouche>
          </div>
        </div>
      </div>
      <div className="container mb">
        <div className="row">
          <div className="column-left">
            <h1>projets</h1>
          </div>
          <div className="column-right">
            <ProjectSlider projects={projects} filterByCategory={state.filterByCategory}></ProjectSlider>
            <a
              href="/projets/"
            >
              tous nos projets
            </a>
            <div className="mb"></div>
          </div>
        </div>
      </div>
      <div className="container mb">
        <div className="row">
          <div className="column-left">
            <h1>actualité</h1>
          </div>
          <div className="column-right">
            {
              posts.map(function (post) {
                return (
                  <CollapsingCard
                    key={post.id}
                    content={post.content}
                    title={post.title}
                    subtitle={post.date}
                    featuredImage={post.featuredImage && post.featuredImage.node}
                    isOpened={
                      post.categories.nodes.length === 0 & state.filterByCategory === undefined ? true :
                        post.categories.nodes.some(
                          (category) => {
                            if (state.filterByCategory) {
                              return category.slug === state.filterByCategory;
                            } else {
                              return category;
                            }
                          }
                        )
                    }></CollapsingCard>
                );
              })
            }
            <div>
              <a
                href="/actualite/"
              >
                toute notre actualité
              </a>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage;
